<template>
  <el-dialog
    title="学员课程管理"
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
    append-to-body
  >
    <el-row class="message">
      <el-col :span="5" :offset="1"><span class="textshow">学号:</span>{{loginName}}</el-col>
      <el-col :span="5"><span class="textshow">姓名:</span>{{userName}}</el-col>
    </el-row>
    <el-row>
      <el-col class="courselist" :offset="1">课程目录</el-col>
      <el-col :offset="1" :span="22">
        <el-row style="margin-bottom: 10px">
          <el-checkbox v-model="checked" @change="checkedAll"/> 全选/反选
        </el-row>
        <el-tree
          ref="tree"
          :data="datalist"
          show-checkbox
          node-key="id"
          :default-expanded-keys="expandedkeys"
          :default-checked-keys="checkedkeys"
          @check-change="handleCheckChange"
          :props="defaultProps">
        </el-tree>
      </el-col>
    </el-row>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="saveData">保 存</el-button>
<!--      <el-button type="primary" size="small" style="margin-left: 20px;" @click="close">取 消</el-button>-->
    </div>
  </el-dialog>
</template>

<script>
// import { getTaskByUser, setTaskByUser, updateQuestionState } from '@/api/senate/class'
import { getTaskByUserAndCourse, setTaskByUserAndCourse, updateQuestionState } from '@/api/senate/class'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    resetUse: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      formLabelWidth: '100px',
      loginName: '',
      userName: '',
      user_id: '',
      expandedkeys: [],
      checkedkeys: [],
      checkedkeysParent: [],
      datalist: [],
      taskArray: [],
      questionArray: [],
      questionorginArray: [],
      plusArray: [],
      deleteArray: [],
      defaultProps: {
        children: 'taskInfoList',
        label: 'title'
      },
      checked: false, // checkbox的值
      courseId: ''
    }
  },
  methods: {
    openDialog () {
      // console.log("rolename"+JSON.stringify(this.resetUse))
      // alert(JSON.stringify(this.resetUse))
      if (this.$route.query.courseId) {
        this.courseId = this.$route.query.courseId
      }
      if (this.$route.query.curriculumID) {
        this.courseId = this.$route.query.curriculumID
      }
      this.loginName = this.resetUse.loginName
      this.userName = this.resetUse.userName
      if (this.userName === undefined) {
        this.userName = this.resetUse.name
      }
      this.getTaskByUserAndCourse()
    },

    checkedAll () {
      if (this.checked) {
        //全选
        this.$refs.tree.setCheckedNodes(this.datalist)
      } else {
        //取消选中
        this.$refs.tree.setCheckedKeys([])
      }
    },

    async getTaskByUserAndCourse () {
      this.datalist = []
      this.checkedkeys = []
      this.taskArray = []
      this.questionArray = []
      this.questionorginArray = []
      const res = await getTaskByUserAndCourse({
        courseId: this.courseId,
        // classId: this.resetUse.class_id,
        userId: this.resetUse.user_id
      })
      this.datalist = res.body
      for (let i = 0; i < this.datalist.length; i++) {
        for (let j = 0; j < this.datalist[i].taskInfoList.length; j++) {
          if (this.datalist[i].taskInfoList[j].type === '编程' && this.datalist[i].taskInfoList[j].questionList != null) {
            var questionListthemb = this.datalist[i].taskInfoList[j].questionList
            for (let k = 0; k < questionListthemb.length; k++) {
              questionListthemb[k].sourceId = this.datalist[i].taskInfoList[j].sourceId
              questionListthemb[k].type = this.datalist[i].taskInfoList[j].type
              questionListthemb[k].parentId = this.datalist[i].taskInfoList[j].id
              questionListthemb[k].sontype = 1
            }
          }
        }
      }
      let reg = new RegExp('questionList', 'g') // g代表全部
      let regid = new RegExp('problem_id', 'g') // g代表全部
      this.datalist = JSON.parse(JSON.stringify(this.datalist).replace(reg, 'taskInfoList').replace(regid, 'id'))
      this.checked = true
      for (let i = 0; i < this.datalist.length; i++) {
        for (let j = 0; j < this.datalist[i].taskInfoList.length; j++) {
          if (this.datalist[i].taskInfoList[j].taskStatus === '已启用') {
            if (this.datalist[i].taskInfoList[j].type !== '编程') {
              this.taskArray.push(this.datalist[i].taskInfoList[j].id)
            }
            if (this.datalist[i].taskInfoList[j].type === '编程' && this.datalist[i].taskInfoList[j].taskInfoList !== null && this.datalist[i].taskInfoList[j].taskInfoList !== 'null') {
              for (let k = 0; k < this.datalist[i].taskInfoList[j].taskInfoList.length; k++) {
                this.datalist[i].taskInfoList[j].taskInfoList[k].id = this.datalist[i].taskInfoList[j].taskInfoList[k].id + '+' + this.datalist[i].taskInfoList[j].taskInfoList[k].parentId
                if (this.datalist[i].taskInfoList[j].taskInfoList[k].userStatus === '已启用') {
                  var thembObjone = {}
                  thembObjone.user_id = this.resetUse.user_id
                  thembObjone.contest_id = parseInt(this.datalist[i].taskInfoList[j].sourceId)
                  thembObjone.problem_id = parseInt(this.datalist[i].taskInfoList[j].taskInfoList[k].id.split('+')[0])
                  thembObjone.status = '已启用'
                  this.questionArray.push(thembObjone)
                  this.taskArray.push(this.datalist[i].taskInfoList[j].taskInfoList[k].id)
                  var thembObj = {}
                  thembObj.sourceId = this.datalist[i].taskInfoList[j].sourceId
                  thembObj.id = parseInt(this.datalist[i].taskInfoList[j].taskInfoList[k].id.split('+')[0])
                  this.questionorginArray.push(thembObj)
                } else {
                  this.checked = false
                }
              }
            }
          } else {
            this.checked = false
          }
        }
      }
      this.checkedkeys = this.checkedkeys.concat(this.taskArray)
    },
    async setTaskByUserAndCourse () {
      var checkedkeysthemb = []
      for (let i = 0; i < this.checkedkeys.length; i++) {
        if (this.checkedkeys[i].indexOf('+') === -1) {
          checkedkeysthemb.push(this.checkedkeys[i])
        }
      }
      const res = await setTaskByUserAndCourse({
        courseId: this.courseId,
        // classId: this.resetUse.class_id,
        userId: this.resetUse.user_id,
        taskIds: checkedkeysthemb.concat(this.checkedkeysParent),
        status: '已启用'
      })
      if (res && res.state === 'success') {
        this.updateQuestionState()
      }
    },
    async updateQuestionState () {
      const res = await updateQuestionState(JSON.stringify(this.deleteArray.concat(this.plusArray)))
      if (res && res.state === 'success') {
        window.$msg('保存成功')
        this.close()
      }
    },
    saveData () {
      this.compareArray()
      this.setTaskByUserAndCourse()
    },
    handleCheckChange () {
      let res = this.$refs.tree.getCheckedNodes()
      this.questionArray = []
      this.checkedkeys = []
      this.checkedkeysParent = []
      res.forEach((item) => {
        if (item.sontype !== 1) {
          this.checkedkeys.push(item.id)
        } else {
          if (item.parentId !== undefined && this.checkedkeys.indexOf(item.parentId) === -1) {
            this.checkedkeysParent.push(item.parentId)
          }
        }
        var thembObj = {}
        if (item.type === '编程' && item.sontype === 1) {
          // console.log(item.id)
          thembObj.user_id = this.resetUse.user_id
          thembObj.contest_id = parseInt(item.sourceId)
          if ((item.id + '').indexOf('+') === -1) {
            thembObj.problem_id = item.id
          } else {
            thembObj.problem_id = parseInt(item.id.split('+')[0])
          }
          thembObj.status = '已启用'
          this.questionArray.push(thembObj)
        }
      })
    },

    compareArray () {
      this.deleteArray = []
      this.plusArray = []
      for (let i = 0; i < this.questionArray.length; i++) {
        var hasdata = false
        for (let j = 0; j < this.questionorginArray.length; j++) {
          if (this.questionArray[i].problem_id === this.questionorginArray[j].id && parseInt(this.questionorginArray[j].sourceId) === this.questionArray[i].contest_id) {
            hasdata = true
          }
        }
        if (!hasdata) {
          this.plusArray.push(this.questionArray[i])
        }
      }

      for (let i = 0; i < this.questionorginArray.length; i++) {
        let hasdata = false
        for (let j = 0; j < this.questionArray.length; j++) {
          if (this.questionorginArray[i].id === this.questionArray[j].problem_id && parseInt(this.questionorginArray[i].sourceId) === this.questionArray[j].contest_id) {
            hasdata = true
          }
        }
        if (!hasdata) {
          var thembObj = {}
          thembObj.user_id = this.resetUse.user_id
          thembObj.contest_id = parseInt(this.questionorginArray[i].sourceId)
          thembObj.problem_id = this.questionorginArray[i].id
          thembObj.status = '已删除'
          this.deleteArray.push(thembObj)
        }
      }
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: left;
  margin-left: 30px;
  margin-bottom: 30px;
}

/deep/ .message {
 font-size: 16px;
  margin-bottom: 30px;
}
/deep/ .courselist{
  color: #222222;
  font-size: 16px;
  margin-bottom: 20px;
}
/deep/ .textshow {
  font-size: 14px;
  color: #222222;
  padding-right: 20px;
}
</style>
